import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  current_case = '';
  constructor(
    private _router: Router,
  ) {
    this._router.events.subscribe(
      res => {
        if (res instanceof NavigationEnd) {
          const url_name = res.url.split('/');
          this.current_case = url_name[1];
        }
      });
  }

  ngOnInit() {
  }

}
