
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { web_page_title } from './app/dce-service/env.service';
import { EnvService } from './app/dce-service/env.service';


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    {provide: EnvService, useValue: web_page_title }
  ]
}).catch(err => console.error(err));

