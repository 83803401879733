import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from './dce-service/shared.service';

@Injectable()
export class Resolver implements Resolve<Observable<any>> {
  constructor(private api: SharedService) { }

  resolve() {
    return this.api.shared_services_before_routing();
  }
}