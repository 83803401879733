import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit} from '@angular/core';
import { faPlus, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import 'bootstrap';
import * as $ from 'jquery';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
	@Input() title_text="hie";
	@Input() placement_direction="right";

  constructor(
    private library: FaIconLibrary
  ) { 
    library.addIcons(faPlus, faTimes, faSearch);
  }

  ngOnInit() {

  }

  ngAfterViewInit(){
  	(<any>$('a')).tooltip();
  }

}
