import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NgCompleterService {

  constructor(	
    private http: HttpClient) { }


    get_searched_data(service_url, payload) {
      return this.http.post<any>(service_url, payload)
    }

}