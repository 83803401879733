import {
    Directive,
    ElementRef,
    // HostListener,
    Input,
    Renderer2,
    SimpleChanges,
  } from '@angular/core';
  
  @Directive({
    selector: '[nzBtnLoader]',
  })
  export class BtnLoaderDirective {
    @Input('nzBtnLoader') btnLoader : boolean = false;
    @Input() loadingText?: string = 'Loading...';
  
    initialText = '';
    constructor(private renderer: Renderer2, private el: ElementRef) {}
  
    ngOnChanges(changes: SimpleChanges) {
      if (changes.btnLoader) {
        const nativeElement = this.el.nativeElement;
        if (changes.btnLoader.currentValue == true) {
          this.initialText = nativeElement.innerHTML;
          this.renderer.setProperty(nativeElement, 'innerHTML', this.loadingText);
          this.renderer.setProperty(
            nativeElement,
            'disabled',
            changes.btnLoader.currentValue
          );
        } else if (
          changes.btnLoader.currentValue == false &&
          this.initialText != ''
        ) {
          this.renderer.setProperty(nativeElement, 'innerHTML', this.initialText);
          this.renderer.setProperty(
            nativeElement,
            'disabled',
            changes.btnLoader.currentValue
          );
        }
      }
    }
  }
  