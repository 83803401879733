import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/dce-service/shared.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  isLoading = false;

  constructor(
    public sharedService: SharedService
  ) {
    this.sharedService.isLoading.subscribe(res => {
      this.isLoading = res;
    });
  }

  ngOnInit() {
  }

}
