import { Directive, Input } from '@angular/core';
@Directive({
  selector: '[LowerCase]',
  host: {
    '(change)': 'toLowerCase($event)',
  }
})
export class LowerCaseTextDirective {
  @Input() control: any;

  constructor() {}

  toLowerCase(value: any) {
    this.control.setValue(this.control.value.toLowerCase())
  }
}
